
// url details of qa
export var apiURL =
{
    //"URL": "https://csdocproapi.azurewebsites.net/"
    //"URL": "https://continuflowapi.azurewebsites.net/"
    // "URL": "http://localhost:3001",
    "URL": "https://us-east4-traffix-automation-prod.cloudfunctions.net/traffix-automation-api",
    //"USECASE_API_URL": "https://us-east4-coyote-prod-carrierautomation.cloudfunctions.net/UseCaseDetectionLoad",
    "RECLASSIFICATION_API_URL": "https://us-east4-traffix-automation-prod.cloudfunctions.net/DocumentDetectionLoad",
    "TMS_LOAD_DETAILS_URL": "https://api-prod.traffix.com/continueflow/v1/LoadLookup?",
    "TMS_CARRIER_DETAILS_URL": "https://api-prod.traffix.com/continueflow/v1/CarriersLookup?",
    "LOAD_DETAILS_PAGE_URL": "https://traffix-automation.web.app/load/view"
    // "MERGE_LOAD_API_URL": "https://us-east4-coyote-prod-carrierautomation.cloudfunctions.net/LoadMerge"
};

export var authConfig = {
    "DEV": {
        clientId: '4cb9c93c-c7bd-4fc6-a343-27e8254e7ba5',
        authority: 'https://login.microsoftonline.com/0dd18e25-6809-4544-a706-fe014c98b3e3',
        redirectUri: 'https://traffix-automation-dev.web.app/dashboard',
        postLogoutRedirectUri: 'https://traffix-automation-dev.web.app/'
    },
    "LOCAL": {
        clientId: '786d7710-beb1-445e-9c0a-e8d8c0d0a734',
        authority: 'https://login.microsoftonline.com/9239fe16-cb61-4517-b24e-a9003e8b30c2',
        redirectUri: 'http://localhost:4200/dashboard',
        postLogoutRedirectUri: 'http://localhost:4200/'
    },
    "SIT": {
        clientId: '786d7710-beb1-445e-9c0a-e8d8c0d0a734',
        authority: 'https://login.microsoftonline.com/9239fe16-cb61-4517-b24e-a9003e8b30c2',
        redirectUri: 'https://traffix-automation-sit.web.app/dashboard',
        postLogoutRedirectUri: 'https://traffix-automation-sit.web.app/'
    },
    "PROD": {
        clientId: '85450898-48ef-4a8e-b355-daf0c615b1ff',
        authority: 'https://login.microsoftonline.com/9239fe16-cb61-4517-b24e-a9003e8b30c2',
         redirectUri: 'https://traffix-automation.web.app/dashboard',
         postLogoutRedirectUri: 'https://traffix-automation.web.app/'
        //redirectUri: 'https://traffix-automation.web.app/dashboard',
        //postLogoutRedirectUri: 'https://traffix-automation-sit.web.app/'
    }
};

export var webURL =
{
    // "URL": "https://csdocproweb.azurewebsites.net/"
    // "URL": "http://localhost:3000"
    "URL": "https://us-east4-traffix-automation-prod.cloudfunctions.net/traffix-automation-api"
};

export var webSocketURL =
{
    "URL": "https://websocket-dot-traffix-automation-prod.uk.r.appspot.com/"
    // "URL": "http://localhost:3001"
};

export var BZ_Email_DL = {
    "EMAIL": "coyote_continuflow@continuserve.com",
    "MAILBOXEMAIL": "traffix_continuflow@continuserve.com"
};

export var TMS_Creds =
{
    "Key": "Ocp-Apim-Subscription-Key",
    "Value": "d51f889f48e1442a9005506461e54d81"
};

export var pspdfkit_key =
{
    //for hosted site
    "Key": "Votepat2jNGSUldv0TJLtu1-dKRGzu78L1Ds7ynFrEmyxc-4cxPbUZ6ydO7dHpgmLqzn6FKg1EEnjG6iRa2p4i26zhW3a1u8c4o2sPnsaOfiLUMW_0JbukbQKQOl--ypTocb3e0_jFCrIgMBiHVrJtU_WX9BSRvLO2pGEqNlmkfyLnwUgGJjn3AGJ8noADO8pW-eXKV5eeWy1POZ303JV3mQee6o1Q_sgzB8zF6WlQl9dJeMprCbdWVAU8d6owIehoVat56DLAiDSKsTeNJOMGmG-oT3kvyJFrDzmkCFxME9dS0qsOn6c2J5q062FE47BZ2QzMtUrwXd1fHpxlNpOuX6I2am2xr8TDa8kF8hFBT6k1Fp26B-6kJ6uf7ZATJb_73D--pU8Dj9re-TCDTfAMY8R4iLZ0e8HNab4DID1_9ImDluxEZIDNwFmTt5i5U-nx1o5fu1a_CR-bvhKMNRbzvr6O6LiAI5tMJ5hQ1uqM8dlvsRyfV1o_K_2IP2_xnalwCA-Rm47d1bndc8JAf5hwQdQVkNji0y6XDkJzw2pbUJmucq0lspReiuZE_LUcLT_lEHsl_-JtD2bIOekmEnzEmx5SggwKuoqv7yhsr2bpBUWRAgAEK3T_wsOh0lDdxy"

   //to run locally
   // "Key": "OBPOuVsRQmh7x1WoOF7vfvmeJLmR0o0DIeb8k_wofBRvUhaKCvAhyN4RUP8KmPAVEHwvi57oFB_z2sYjUnRJZAECIDCn5AorIaBQ3WnhkIXYQMspwPrO0wtG897zyKr-sQ8U8Nl_xj7_AOvammrFpD-CEIKDkokJbFj1CAOVRgdDKDxap846DMspUROdAbbejgGJCzOKvrOWbEhsO-Pubg1LabjJVyFdePBWgjItwCc7XdyeS8so0IQSP4qvShRxMdu0JCXLWMFE_1w3KEwKbM3DaKMC3cO47_kwPShHgjNBRqIYkVGkAu71gbo2Or27tMaeSWY6xkXWoRRzS-8LbyE_zFIH514pLWTYAVjEMw2d0RzeyCTbSV7daL9YCcTSJcjHwZtHEdCHDimEtigB0wcuc6GUqiiZ2j1pc6sEdzfa41nbXjO0WpLzpNni9wwgxtqHK8l-QO2JNfGrbGjhLiw9n_JRlRXXNixbnyHlCaDSTAqGG1XGECdfT0QGLVv5RKTUAk9g4q8WkORJvDxX3EqT2DNkGyRpgsUMwGuThDJdGL15T7AX3VfUCLPBRqPmeM3YZOxeYrgR4tYxQqevUBQredmJH3KJBVgwK_6T72ZJZSb_3462dXpqOhgKYbyN"

};

export var support =
{
    mailTo: "traffix_continuflow@continuserve.com"
};
export var mailboxes = {
    "FIRST_MAILBOX_NAME": "Carrier Payment Pilot",
    "FIRST_MAILBOX_ID": "carrierpaymentpilot@traffix.com",
    "SECOND_MAILBOX_NAME" : "Sandbox 2",
    "SECOND_MAILBOX_ID": "carrierpayment.sandbox2@traffix.com",
};
export var secretKey = "Secret12345"

// url details of prod
// export var apiURL =
// {
//     "URL": "https://continuflowapi.azurewebsites.net/"
// };

// export var webURL =
// {
//     "URL": "https://continuflow.azurewebsites.net/"
// };
